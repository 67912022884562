import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { setShow } from '../../redux/slices/solutionSlice'
import { unsafeExport } from '../../functions/makeReceiptPDF'
import { mintSolution } from '../ListOfChallenges/functions'
import { ethers } from 'ethers'
import currentAuthUser from '../../functions/currentAuthUser'
import Title from '../Title/Title'
import CloseIcon from '@material-ui/icons/Close';
import { Tooltip } from '@material-ui/core'

/**
 * helpClaimFunds
 * August 18th 2022
 * William Doyle
 */
async function helpClaimFunds(claimFrom_pri: string, callback: () => void) {
    console.log('STUB INSIDE helpClaimFunds')
    try {
        const send_to = await currentAuthUser()
        const provider = new ethers.providers.Web3Provider((window as any).ethereum)

        const w: ethers.Wallet = new ethers.Wallet(claimFrom_pri, provider)

        const balance = await w.getBalance()
        const feeData = await provider.getFeeData()

        if (feeData.gasPrice === null)
            throw new Error('gasPrice is null')

        const tx = {
            to: send_to,
            value: balance.sub(ethers.utils.parseEther('0.01')), // TODO: make this smarter
            gasPrice: feeData.gasPrice,
        }

        console.log(`ProcessSolution::helpClaimFunds signing transaction`)
        await w.signTransaction(tx)
            .catch((e: any) => {
                console.log(`ProcessSolution::helpClaimFunds error signing transaction`)
                console.log(e)
            })

        console.log(`ProcessSolution::helpClaimFunds sending transaction`)
        await w.sendTransaction(tx)
            .catch((e: any) => {
                console.log(`ProcessSolution::helpClaimFunds error sending transaction`)
                console.log(e)
            })
    } catch (e) {
        callback()
    }

}


type ProcessSolutionProps = {
    setShowSnackbar: (show: boolean) => void,
    setSnackbarMessage: (message: string) => void,
}


/**
 * August 18th 2022
 * William Doyle
 * @returns {JSX.Element}
 */
export default function ProcessSolution(props: ProcessSolutionProps) {
    const dispatch = useDispatch()
    const cd = useSelector((state: any) => state.contractData.contractData) // contract data
    const solution = useSelector((state: any) => state.solutionInfo.solution)
    const show = useSelector((state: any) => state.solutionInfo.show)

    if (!show)
        return <> </>
    return <div className="process-solution">
        <div className="process-solution-header">

            <Title text={`🎈🥳 Solution Found to Challenge ${solution.challengeDataToShow.position}🎈🥳`} level={3} />
            <Title text='Press Mint to issue your NFT certificate and Claim Prize Money to collect your reward.' level={5} />
            <CloseIcon onClick={() => dispatch(setShow(false))} />
        </div>
        <div className="process-solution-body">

            <div className={'coloumn'}>
                <div className="row">
                    <div className='rowItem'>
                        <Tooltip title='Click to withdraw your cash (MATIC) reward' >

                            <button
                                className="btn-type-2"
                                onClick={() => helpClaimFunds(solution.solution, () => {
                                    props.setSnackbarMessage('You Must Have Metamask Installed and Logged In to Claim Prize Money')
                                    props.setShowSnackbar(true)
                                })}
                            >
                                Claim Prize Money
                            </button>
                        </Tooltip>
                    </div>

                    <div className='rowItem'>
                        <Tooltip title='Click to begin minting your reward NFT' >
                            <button
                                className="btn-type-2"
                                onClick={() => {
                                    mintSolution(solution.solution, parseInt(solution.challengeDataToShow.position), new ethers.Contract(cd.address, cd.abi))
                                        .catch((e: any) => {
                                            // props.setSnackbarMessage(e.message)
                                            props.setSnackbarMessage('You Must Have Metamask Installed and Logged In to Mint NFTs')
                                            props.setShowSnackbar(true)
                                        })
                                }}
                            >
                                Mint NFT Certificate
                            </button>
                        </Tooltip>
                    </div>

                    <div className='rowItem'>
                        <Tooltip title='Click to save your solution. Use this option if you are unable to mint at this time.'>
                            <button
                                className="btn-type-2"
                                onClick={() => unsafeExport(solution.challengeDataToShow, solution.solution, cd)}
                            >
                                Download Solution As PDF
                            </button>
                        </Tooltip>
                    </div>
                </div>
            </div>
        </div>
    </div>
}