import blockchains from './blockchains';
import ContractData from '../classes/ContractData';

import IKrombopulosMichael from '../contractData/IKrombopulosMichael/IKrombopulosMichael.json'
import IKrombopulosMichaelAddress from '../contractData/IKrombopulosMichael/address.json'

const productionContractData: ContractData = {
    abi: IKrombopulosMichael.abi,
    contractName: IKrombopulosMichael.contractName,
    address: IKrombopulosMichaelAddress.polygon,
    description: `Polygon to layer 2 ethereum`,
    blockchain: blockchains.polygon,
    marketplaceURL: 'https://opensea.io/collection/proof-of-quantum-certificate',
    marketShortName: 'OpenSea',
}

export {
    productionContractData
}

const contractOptions: ContractData[] = [
    {
        abi: IKrombopulosMichael.abi,
        contractName: IKrombopulosMichael.contractName,
        address: IKrombopulosMichaelAddress.moonbase,
        description: `Type 1 challenge's involving private keys with redacted bits. Now with sponsorship support!`,
        blockchain: blockchains.moonbase,
        marketplaceURL: '#',
        marketShortName: 'Marketplace',
    },
    // {
    //     abi: IKrombopulosMichael.abi,
    //     contractName: IKrombopulosMichael.contractName,
    //     address: IKrombopulosMichaelAddress.rinkeby,
    //     description: `on rinkeby because we want to test on opensea`,
    //     blockchain: blockchains.rinkeby
    //   marketplaceURL  :`https://testnets.opensea.io/collection/proof-of-quantum-certificate-v4`,
    // },
    //  {
    //     abi: IKrombopulosMichael.abi,
    //     contractName: IKrombopulosMichael.contractName,
    //     address: IKrombopulosMichaelAddress.rinkeby,
    //     description: `on rinkeby because we want to test on opensea`,
    //     blockchain: blockchains.rinkeby,
    //     marketplaceURL : '#',
    //     marketShortName: 'Marketplace',
    // },
    // {
    //     abi: IKrombopulosMichael.abi,
    //     contractName: IKrombopulosMichael.contractName,
    //     address: IKrombopulosMichaelAddress.ropsten,
    //     description: `Ropsten to test with various chains`,
    //     blockchain: blockchains.ropsten,
    //     marketplaceURL : '#',
    //     marketShortName: 'Marketplace',
    // },
    {
        abi: IKrombopulosMichael.abi,
        contractName: IKrombopulosMichael.contractName,
        address: IKrombopulosMichaelAddress.polygon,
        description: `Polygon to layer 2 ethereum`,
        blockchain: blockchains.polygon,
        marketplaceURL: 'https://opensea.io/collection/proof-of-quantum-certificate',
        marketShortName: 'OpenSea',
    }
]
export default contractOptions
