/*
    QuickFill.tsx
        A tool to allow the user to compute a value and set it as an input field.
    William Doyle
    May 24th and 25th 2022
*/

import React from 'react';
import { useSelector, useDispatch } from 'react-redux'
import Dropdown from 'react-dropdown';
import { ethers } from 'ethers'
import newInitCallData, { getProofSigs } from '../../functions/newInitCallData';
import { loremIpsum } from "lorem-ipsum";
import { setGeneratedInputValue, setTargetParam } from '../../redux/slices/generatedInputValueSlice';

/*
    getContractFunctionOptions
    A list of objects describing the functions available on the contract.
    William Doyle
    May 24th 2022
*/
function getContractFunctionOptions(contract: ethers.Contract): any[] {
    const options: any[] = []

    contract.interface.fragments.filter((f: any) => f.type === 'function').reverse().map((f: any) => ({
        label: f.name,
        value: f,
        key: ethers.utils.id(f.name)
    })).forEach((f: any) => options.push(f))

    return options
}

/*
    getGeneratorFunctions
    A list of objects describing the thing to do to generate the value.
    William Doyle
    May 24th 2022
*/
function getGeneratorFunctions(): any[] {
    return [
        {
            // label: 'newInitCallData',
            label: 'generate puzzles',
            value: () => newInitCallData,
            key: ethers.utils.id('newInitCallData')
        },
        {
            label: 'generate puzzle proof sigs',
            value: () => getProofSigs,
            key: ethers.utils.id('generate puzzle proof sigs')
        },
        {
            label: 'Lorem Ipsum',
            value: () => loremIpsum,
            key: ethers.utils.id('Lorem Ipsum')
        },
        {
            label: 'Address Shortcut (Pauli Group\'s address)',
            value: () => () => '0x94D4Da7bDe814ae3B7a3D25A108391018e1e495E',
            key: ethers.utils.id('Address Shortcut (Pauli Group\'s address)')
        },
        // {
        //     label: 'solve private key (cheat)',
        //     value: () => solvePrivateKey,
        //     key: ethers.utils.id('solve private key (cheat)')
        // },
    ]
}

export default function QuickFill(props: {}) {
    const dispatch = useDispatch()

    const cd = useSelector((state: any) => state.contractData.contractData)
    React.useEffect(
        () => setContract(new ethers.Contract(cd.address, cd.abi)),
        [cd.address, cd.abi]
    )

    const generatedArgument = useSelector((state: any) => state.generatedInputValue.value)
    React.useEffect(
        () => console.log(generatedArgument),
        [generatedArgument]
    )


    const [contract, setContract] = React.useState(new ethers.Contract(cd.address, cd.abi))

    const [targetFunction, setTargetFunction] = React.useState(null as any)
    const [callDataGenerator, setCallDataGenerator] = React.useState(null as any)
    const [targetField, setTargetField] = React.useState(null as any) // the argument we are trying to build

    const target = useSelector((state: any) => state.generatedInputValue.target)

    /*
        getInputFieldOptions
            Returns an array of objects that can be used to populate the dropdown.
            Each option is a parameter of the target function.
        William Doyle
        May 24th 2022
    */
    function getInputFieldOptions(): any[] {
        if (targetFunction === null) return []
        const options: any[] = []
        console.log(`targetFunction: ${targetFunction}, or --> `, targetFunction)

        targetFunction.inputs.forEach((i: any) => {
            options.push({
                label: i.name,
                value: i,
                key: ethers.utils.id(i.name)
            })
        })
        return options
    }

    /*
        generateCallData    
        Run the function stored in "callDataGenerator" and save the result to state
        William Doyle
        May 25th 2022
    */
    async function generateCallData(): Promise<void> {
        if (typeof callDataGenerator !== 'function')
            throw new Error(`callDataGenerator is not a function, you must select a function to generate the call data.`)
        const callData = await callDataGenerator()
        console.log(`callData: ${callData}, or --> `, callData)

        // use dispatch to update the state
        if (callData === null || callData === undefined)
            throw new Error(`callData was empty`)

        dispatch(setGeneratedInputValue(callData) as any)
    }


    /*
        passGeneratedFunctionParam()
        Pass the generated data to the target field of the target function
        William Doyle
        May 25th 2022
    */
    async function passGeneratedFunctionParam(): Promise<void> {
        console.log(`STUB --> passGeneratedFunctionParam()`)
        dispatch(setTargetParam({
            function_name: targetFunction.name,
            target_param: targetField.name,
        }))
    }

    // React.useEffect(() => {
    //     // set up the default values for the dropdowns
    //     setTargetFunction
    // }, []);

    return <div className="QuickFill" style={{ textAlign: 'start', borderStyle: 'solid', backgroundColor: 'red' }}>
        <h2> QuickFill </h2>
        <div className="QuickFill-State-Window">
            <table>
                <tr>
                    <th>
                        Selection Name
                    </th>
                    <th>
                        Selected Value
                    </th>
                </tr>
                <tr>
                    <td>
                        <label>Target Function</label>
                    </td>
                    <td>
                        {targetFunction ? targetFunction.name : 'Select a function'}
                    </td>
                </tr>
                <tr>
                    <td>
                        <label>Call Data Generator</label>
                    </td>
                    <td>
                        {callDataGenerator ? callDataGenerator.name : 'Select a generator'}
                    </td>
                </tr>
                <tr>
                    <td>
                        <label>Target Field</label>
                    </td>
                    <td>
                        {targetField ? targetField.name : 'Select a field'}
                    </td>
                </tr>
            </table>
        </div>
        <div className='QuickFill-Input-Window'>
            <Dropdown
                options={getContractFunctionOptions(contract)}
                onChange={(e: any) => setTargetFunction(e.value)}
                placeholder="Select a target function"
            />
            <Dropdown
                options={getGeneratorFunctions()}
                onChange={(e: any) => setCallDataGenerator(e.value)}
                placeholder="Select a generator function"
            />
            <Dropdown
                options={getInputFieldOptions()}
                onChange={(e: any) => setTargetField(e.value)}
                placeholder="Select a target field"
            />
            <button onClick={generateCallData}> Generate Data</button>
            <button onClick={passGeneratedFunctionParam} > Set function argument</button>
        </div>

        <output>
            <h3>Generated Call Data</h3>
            {
                JSON.stringify(generatedArgument ? generatedArgument : 'No data generated')
            }
        </output>

        <output>
            <h3>The Target</h3>
            {
                JSON.stringify(target ? target : 'No target selected')
            }
        </output>
    </div>
}