import React from 'react'
import { CartesianGrid, XAxis, YAxis, Tooltip, ScatterChart, Scatter, ResponsiveContainer, Label, Cell } from 'recharts'
import { useSelector, useDispatch } from 'react-redux'
// import useSmartContract from '../../hooks/useSmartContract'
import { setDataPoints, setLoaded } from '../../redux/slices/dataPointsSlice'
import { color5, color1 } from '../../constants/colors';
import ScoreboardEntry from './ScoreboardEntry'
import processDataPoints from './processDataPoints'
import { default as NON_MINTED_DATA } from './AdditionalData.json'
import useViewSmartContract from '../../hooks/useViewSmartContract';

/**
 * Scoreboard
 * Shows graph where Y is difficulty and X is chronological order
 * William Doyle
 * */
export default function Scoreboard() {
    const dispatch = useDispatch()
    // const rawdata = useSmartContract('getDataPoints')
    const rawdata = useViewSmartContract('getDataPoints')
    const _data = useSelector((state: any) => state.dataPoints.dataPoints)
    const _loaded = useSelector((state: any) => state.dataPoints.dataPoints)

    React.useEffect(() => {
        (async () => {
            if (rawdata === null)
                return
            if (_loaded)
                return

            const processedData: ScoreboardEntry[] = processDataPoints(rawdata)

            // load data points relating to unminted... but obviously solved due to outgoing transactions (the grey points)
            // 1. get the data points 
            const additionalDataPoints: ScoreboardEntry[] = NON_MINTED_DATA?.filter(el => el.first_outbound_tx_id !== "")?.map(el => ({
                ts_submission: parseInt(el.first_outbout_tx_timestamp),
                ts_puzzle_posted: processedData[0].ts_puzzle_posted, // they were all posted around the same time.. this is fine
                time_to_solve: 0,
                difficulty: 0,
                difficulty_a: 0,
                pubkey: '',
                challenge_id: '',
                solvedBy: '',
                tokenId: '',
                asset_url: '',
                time_since_last_mint: 0,
                unknown_bits: el.unknown_bits,
                is_mint_event: false,
            }))

            // 2. append to `processedData`
            processedData.push(...additionalDataPoints)

            dispatch(setLoaded(true))

            // put the data into the redux store
            dispatch(setDataPoints(processedData))
        })()
    }, [rawdata, _loaded, dispatch])

    return <div className='scoreboard' id={'main-event'}>
        <div className='row'>
            <div style={{ flex: 1, }} />
            <div style={{ flex: 2 }} >
                <ResponsiveContainer width='100%' height={600}>
                    <ScatterChart data={_data}>
                        {(() => {
                            if (_data === null)
                                return <XAxis stroke={color5} />
                            const dates: number[] = _data.map((entry: ScoreboardEntry) => entry.ts_submission)
                            const minDate = Math.min(...dates)
                            const maxDate = Math.max(...dates)

                            return <XAxis
                                dataKey='ts_submission'
                                scale='time'
                                type='number'
                                domain={[minDate, maxDate]}
                                stroke={color5}
                                tickFormatter={(ts: number) => new Date(ts * 1000).toLocaleString()}
                            />
                        })()
                        }
                        <YAxis
                            domain={[0, 256]}
                            stroke={color5}
                            ticks={[0, 64, 128, 192, 256]}
                        >
                            <Label fill={color5} angle={-90} value='Difficulty (bits)' position='insideLeft' style={{ textAnchor: 'middle' }} />
                        </YAxis>
                        <CartesianGrid stroke={color5} strokeDasharray="5 5" />
                        <Tooltip />
                        <Scatter
                            type="monotone"
                            dataKey="unknown_bits"
                            stroke={color5}
                            fill={color1}
                        >
                            {
                                _data?.map((entry: ScoreboardEntry, index: number) => (
                                    <Cell key={`cell-${index}`} fill={entry.is_mint_event ? color1 : color5} />
                                ))
                            }

                        </Scatter>
                    </ScatterChart>
                </ResponsiveContainer>
            </div>
            <div style={{ flex: 1 }} />
        </div>
    </div>
}