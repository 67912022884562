import { firebaseConfig } from "../constants/firebaseConfig";
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
import store from "../redux/store";
import Dev from "../classes/Dev";

type EventTypes = "app_load" | "solve_btn_pressed" | "sponsor_btn_pressed" | "solution_found"

/**
 * August 19th 2022
 * William Doyle
 * LogEvent
 * Logs an event to the Firebase Analytics service.
 */
export default function LogEvent(eventType: EventTypes, eventParams: any = {}) {
    const app = initializeApp(firebaseConfig);
    const analytics = getAnalytics(app);

    const state = store.getState()

    const chain_id = state?.contractData?.contractData?.['blockchain']?.chainid ?? -1

    Dev.log(`LogEvent: chain_id is ${chain_id}`)

    logEvent(analytics, eventType, {
        ...eventParams,
        chain_id: chain_id,
    });
}