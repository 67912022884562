import { useSelector } from 'react-redux'

type Position = 'top' | 'bottom' | 'both'

/**
 * Element
 * An element in the list of links
 * William Doyle
 * August 6th 2022
 */
type Element = {
  displayText: string
  resolveUrl: Function
  position: Position
}

const elemnets: Element[] = [
  {
    displayText: `Copyright © ${new Date().getFullYear()} Pauli Group - All Rights Reserved.`,
    resolveUrl: () => 'https://www.pauli.group',
    position: 'bottom',
  },
  {
    displayText: 'Linkedin',
    resolveUrl: (cd: any) => `https://www.linkedin.com/company/pauli-group/about/`,
    position: 'bottom',
  },
  {
    displayText: 'Twitter',
    resolveUrl: (cd: any) => `https://twitter.com/pauli_group`,
    position: 'bottom',
  },
  {
    displayText: 'Buy And Sell',
    // resolveUrl: (cd: any) => `https://testnets.opensea.io/collection/proof-of-quantum-certificate-v4`
    resolveUrl: (cd: any) => cd?.marketplaceURL,
    position: 'top',
  },
  {
    displayText: 'Get Metamask',
    resolveUrl: (cd: any) => `https://metamask.io/download/`,
    position: 'top',
  },
  {
    displayText: 'View Contract Activity',
    resolveUrl: (cd: any) => `${cd?.blockchain?.explorerBaseUrl}${cd?.address}`,
    position: 'top',
  },
  {
    displayText: 'Read The Blog Post To Learn More',
    resolveUrl: (cd: any) => `https://pauli.group/f/proof-of-quantum-challenges`,
    position: 'top',
  }
]

/*
    SocialMediaLinks.tsx
    This component is used to display the social media links. And the link to view the contract in the blockchain explorer.
    William Doyle
    July 6th 2022
    params: cd | text_only | bottom 
*/
export default function SocialMediaLinks(props: any) {
  const cd = useSelector((state: any) => state.contractData.contractData)
  const position: Position = props.position

  if (position === 'both' || position === undefined)
    throw new Error('SocialMediaLinks: position cannot be `both` or `undefined` (must be `top` or `bottom`)')

  return <div className="row">
    {
      elemnets.filter(el => {
        if (el.position === 'both' || el.position === position)
          return true
        return false
      }).map((element: Element, index: number) => {
        return <div key={element.displayText} className="single-link">
          <a href={element.resolveUrl(cd)} target="_blank" rel="noopener noreferrer">
            {element.displayText}
          </a>
        </div>
      })
    }
  </div>
}
