import count_unknown_digit from "./count_unknown_digit"
import { ethers } from "ethers"
import startTimer from "./startTimer";
import LogEvent from "./LogEvent";

function publicKeyFromPrivateKey(privateKey: string): string {
    const w: ethers.Wallet = new ethers.Wallet(privateKey);
    return `0x${w.publicKey.substring(4)}`;
}

export async function _solvePuzzle(pub: string, puzzle: string): Promise<string> {
    const unknown_digit_count = count_unknown_digit(puzzle)
    console.log(`unknown_digit_count --> "${unknown_digit_count}"`)

    if (unknown_digit_count === 0) {
        if (pub === publicKeyFromPrivateKey(puzzle))
            return puzzle
        throw new Error(`Bad inputs! Puzzle does not match pub`)
    }

    const known_part: string = puzzle.substring(0, (puzzle.length) - unknown_digit_count)
    const max_guess_s: string = 'f'.repeat(unknown_digit_count)
    const max_guess_i: number = parseInt(max_guess_s, 16)

    const timer = startTimer()
    for (let guess_i = 0; guess_i <= max_guess_i; guess_i++) {
        const guess: string = `${known_part}${guess_i.toString(16).padStart(unknown_digit_count, '0')}`
        if (pub === publicKeyFromPrivateKey(guess.substring(2))) {
            console.log(`solvePuzzle took ${timer()} seconds`)
            return guess;
        }
    }

    return 'nothing'
}

const pari_solver = (__pub: string, __hint: string) => `step=2^8;p= 2^256 - 2^32 - 2^9 - 2^8 - 2^7 - 2^6 - 2^4 - 1;public=Vec("${__pub}");u=Vec("${__hint}");e=ellinit([0,0,0,0,7],p);n=ellgroup(e,p)[1];eu=0;publicx=0;publicy=0;x1=Vec("79BE667EF9DCBBAC55A06295CE870B07029BFCDB2DCE28D959F2815B16F81798");q=Vec("0123456789ABCDEF");x=0;for(i=1,length(x1),for(j=1,16,if(q[j]==x1[i],x+=(j-1)*16^(64-i))));y1=Vec("483ADA7726A3C4655DA4FBFC0E1108A8FD17B448A68554199C47D08FFB10D4B8");y=0;for(i=1,length(y1),for(j=1,16,if(q[j]==y1[i],y+=(j-1)*16^(64-i))));g=[Mod(x,p),Mod(y,p)];q=Vec("0123456789abcdef");for(i=1,64,for(j=1,16,if(q[j]==public[i],publicx+=(j-1)*16^(64-i))));for(i=65,128,for(j=1,16,if(q[j]==public[i],publicy+=(j-1)*16^(128-i))));publickey=[Mod(publicx,p),Mod(publicy,p)];for(i=1,length(u),for(j=1,16,if(q[j]==u[i],eu+=(j-1)*16^(length(u)-i))));
h=elladd(e,publickey,ellmul(e,g,-16^(64-length(u))*eu));c=ellmul(e,g,16^(64-length(u))); b1=0;for(i=1,step,b=component(c[1],2)%step+1 ;b1+=b;c=elladd(e,c,ellmul(e,g,b)); );a1=0;count=0;while((1 == 1)*(h!=c),a=component(h[1],2)%step+1;a1+=a;h=elladd(e,h,ellmul(e,g,a));count++;);r=(b1-a1+16^(64-length(u)))%n;print(r);h=elladd(e,publickey,ellmul(e,g,-16^(64-length(u))*eu));r2=r;v=vector(64-length(u));for(i=1,64-length(u),v[64-length(u)-i+1]=r2%16;r2=(r2-(r2%16))/16);for(i=1,64-length(u),v[i]=q[v[i]+1]);v`


export function build_pari_program(pub: string, puzzle: string): [string] {
    const pub_s: string = pub.substring(2)
    const unknown_digit_count = count_unknown_digit(puzzle)
    const known_part: string = puzzle.substring(0, (puzzle.length) - unknown_digit_count)
    const hint_s: string = known_part.substring(2)
    const pari_s: string = pari_solver(pub_s, hint_s)
    return [pari_s,]
}

export default async function solvePuzzle(pub: string, puzzle: string): Promise<string> {

    console.log(`stub inside solvePuzzle`)
    console.log(`using pari to solve this puzzle`)

    console.log(`1. puzzle --> `, puzzle)
    const unknown_digit_count = count_unknown_digit(puzzle)

    // if unknown_digit_count is small enough.., use the javascript solver instead of pari
    // if (unknown_digit_count <= 1)
    // return await _solvePuzzle(pub, puzzle)

    // remove the last `n` digits from the puzzle
    const known_part: string = puzzle.substring(0, (puzzle.length) - unknown_digit_count)

    // drop the first two characters from the public key
    const pub_s: string = pub.substring(2)
    // drop the first two characters from the hint
    const hint_s: string = known_part.substring(2)
    console.log(`2. hint_s --> "${hint_s}"`)

    const pari_s: string = pari_solver(pub_s, hint_s)

    await (window.self as any).init_pari()
    const result = await (window.self as any).run_pari_script(pari_s)
    console.log(`3. _result --> "${result}"`)

    // log the event to firebase without revealing the private key
    LogEvent('solution_found', {
        unknown_digit_count: unknown_digit_count
    })

    const ans: string = `${known_part}${parseInt(result.trim()).toString(16).padEnd(unknown_digit_count, '0')}`
    console.log(`4. ans --> `, ans)
    console.log(`5. `, ans.length, `ans.length`)
    console.log(`6. `, puzzle.length, `puzzle.length`)

    return ans
}
