/*
    June 9th 2022
    useSmartContract hook
    William Doyle
    Allows for the use of a smart contract without concern for the details
*/
import React from 'react'
import { useSelector } from 'react-redux'
import { ethers } from 'ethers';
import PolygonProvider from '../PolygonProvider';
// import ProviderSingleton from '../classes/ProviderSingleton';

/**
 * @name useViewSmartContract
 * @description like useSmartContract, but does not require a wallet to be connected
 * @author William Doyle
 * @date Febuary 2nd 2023 
 */
export default function useViewSmartContract(functionName: string, ...args: any[]) {
    const cd = useSelector((state: any) => state.contractData.contractData)
    const [contract, setContract] = React.useState(null as ethers.Contract | null)
    const [result, setResult] = React.useState(null as any);

    React.useEffect(() => {
        if (!cd)
            return
        (async () => {
            // const provider = new ethers.providers.Web3Provider((window as any).ethereum);
            // await provider.send("eth_requestAccounts", []);
            // const provider = await ProviderSingleton.getInstance();
            // const signer = provider.getSigner()
            const provider = ethers.getDefaultProvider(PolygonProvider);
            const _contract = new ethers.Contract(cd.address, cd.abi, provider);

            setContract(_contract)
        })()
    }, [cd])

    React.useEffect(() => {
        if (!contract)
            return

        (async () => {
            try { // 
                const r = await contract[functionName](...args)
                setResult(r)
            } catch (e) {
                console.log(`AN ERROR OCCURED WHILE USING THE useViewSmartContract HOOK. THE FUNCTION NAME WAS ${functionName} AND THE ARGS WERE ${args}`)
                console.log(`FUNCTION NAME --> `, functionName)
                console.log(`ARGS --> `, args)
                throw e
            }
        })()
    }, [contract, functionName, args])

    return result
}