import { ethers } from 'ethers'

const rpcs = [
            'https://polygon.llamarpc.com',
            'https://polygon.rpc.blxrbdn.com',
            'https://endpoints.omniatech.io/v1/matic/mainnet/public',
            'https://polygon-bor.publicnode.com',
        ]


export default class ProviderSingleton {
    private static instance: ethers.providers.Web3Provider
    // private static instance: ethers.providers.FallbackProvider
    private constructor() { }

    static async getInstance(): Promise<ethers.providers.Web3Provider> {
    // static async getInstance(): Promise<ethers.providers.FallbackProvider> {
        if (!ProviderSingleton.instance) {
            ProviderSingleton.instance = new ethers.providers.Web3Provider((window as any).ethereum)
            // ProviderSingleton.instance = new ethers.providers.FallbackProvider([new ethers.providers.Web3Provider((window as any).ethereum), ...rpcs.map(rpc => ethers.getDefaultProvider(rpc))])
            await ProviderSingleton.instance.send("eth_requestAccounts", [])
        }

     
        return ProviderSingleton.instance;
    }
}