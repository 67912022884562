import React from "react"
import { ethers } from "ethers"

/**
 *  @name BlockSearcher
 *  @date October 4th 2022
 *  @author William Doyle
 *  @description get blocknumber from user and display block data
 */
export default function BlockSearcher() {
    const [blockNumber, setBlockNumber] = React.useState<number>(0)
    const [block, setBlock] = React.useState<string>("{}")

    React.useEffect(() => {
       (async () => {
            const provider = new ethers.providers.Web3Provider((window as any).ethereum);
            const data = await provider.getBlock(blockNumber)
            setBlock(JSON.stringify(data, null, 2))
       })() 
    }, [blockNumber])

    return <div className="Block-Searcher">
        <div className="Block-Searcher-Input">
            <label htmlFor="block-searcher-input">Block Number</label>
            <input
                type="number"
                id="block-searcher-input"
                value={blockNumber}
                onChange={(e) => setBlockNumber(parseInt(e.target.value))}
            />
            <label htmlFor="block-searcher-input">{blockNumber}</label>
        </div>
        <div className="block-searcher-output">
            {
                block
            }
        </div>
    </div>
}